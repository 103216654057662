@tailwind base;
@tailwind components;
@tailwind utilities;

body {

    font-family: 'Satoshi !important';
}

.dialog-animate-full .MuiPaper-root {
    margin: 0;
}

.song-for .MuiInputLabel-root {
    color: white !important
}

.own-fieldset .MuiOutlinedInput-notchedOutline,
.comment-field .MuiOutlinedInput-notchedOutline {
    border-width: 0 !important;
}

.comment-field input,
.comment-field input::placeholder,
.comment-field textarea,
.comment-field textarea::placeholder  {
    color: #11202C;
    font-weight: 500;
}

.content-editable::before {
    content: 'Write something'
}

.content-editable:not(:empty)::before {
    content: '';
}

.artists-wrapper {
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap: 10px;
    -moz-column-count: 3;
    -moz-column-gap: 10px;
    column-count: 3;
    column-gap: 20px;
    margin-top: 80px;
}

/* html {
    overflow: hidden;
    height: 100%;
}

body {
    overflow: auto;
    height: 100%;
} */


.lds-dual-ring,
.lds-dual-ring:after {
    box-sizing: border-box;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6.4px solid currentColor;
    border-color: currentColor transparent currentColor transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}