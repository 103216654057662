/* font-display: swap; Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
/* woff2 supported by Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */

/* Satoshi-Black */
@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 900;
    src: url('Satoshi-Black.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-BlackItalic */
@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 900;
    src: url('Satoshi-BlackItalic.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-Bold */
@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    src: url('Satoshi-Bold.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-BoldItalic */
@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 700;
    src: url('Satoshi-BoldItalic.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-Italic */
@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 400;
    src: url('Satoshi-Italic.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-Light */
@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 300;
    src: url('Satoshi-Light.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-LightItalic */
@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 300;
    src: url('Satoshi-LightItalic.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-Medium */
@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    src: url('Satoshi-Medium.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-MediumItalic */
@font-face {
    font-family: 'Satoshi';
    font-style: italic;
    font-weight: 500;
    src: url('Satoshi-MediumItalic.woff2') format('woff2');
    font-display: swap;
}

/* Satoshi-Regular */
@font-face {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 400;
    src: url('Satoshi-Regular.woff2') format('woff2');
    font-display: swap;
}


.font-satoshi {
    font-family: 'Satoshi', sans-serif;
}